<template>
  <div class="custom-component">
    <div v-if="printRead">
      <div class="textarea-block">{{ dataModel }}</div>
    </div>
    <div v-else>
      <el-input type="textarea" :rows="6" v-model="dataModel" placeholder="请输入" style="width: 100%"></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'question-textarea',
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    printRead: {
      type: Boolean,
    },
  },
  data() {
    return {
      dataModel: this.modelValue
    }
  },
  watch: {
    modelValue(val) {
      this.dataModel = val
    },
    dataModel(val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>

<style lang="scss">
.custom-component {
  padding: 10px;

}
.textarea-block {
  background-color: var(--el-background-color-base);
  padding: 10px;
  white-space: pre-wrap;
}
</style>
