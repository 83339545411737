import http from "@/utils/request"

export default {
    // 人才基本信息
    getStaffInfo: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/staff/info`,
        name: "查询员工基本信息",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    updateStaff: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/staff/update`,
        name: "修改员工信息",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

}
