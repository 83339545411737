import http from "@/utils/request"
import { ElLoading, ElNotification } from "element-plus";
import tool from "@/utils/tool";

export default {
  queryFileUploadUrl: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/file/upload_url`,
    name: "获取上传url",
    post: async function (data) {
      return await http.post(this.url, data);
    }
  },
  queryFileDownloadUrl: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/file/download_url`,
    name: "获取下载url",
    post: async function (data) {
      return await http.post(this.url, data);
    }
  },

  downloadFromMinio: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/file/download_url`,
    name: "下载文件 从minio",
    post: async function (objectName, fileName) {
      // 打开
      const loading = ElLoading.service({
        text: '正在下载数据...',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      return await http.post(this.url, { object_name: objectName }).then(async res => {
        if (res.code === 200) {
          let url = res.data.url
          await fetch(url).then(res => {
            loading.close()

            // 获取数据类型
            const status = res.status
            if (status !== 200) {
              if (status === 404) {
                ElNotification.error({
                  title: '下载错误',
                  message: '文件不存在！'
                })
              } else {
                ElNotification.error({
                  title: '下载错误',
                  message: res.statusText || `Status:${status}，未知错误！`
                })
              }
              return
            }

            // 将链接地址字符内容转变成blob地址
            res.blob().then(blob => {
              // 模拟下载行为
              let link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', fileName)
              link.click()
              link = null
              ElNotification.success('下载成功!')
            })
          }).catch(err => {
            loading.close()
            ElNotification.error({
              title: '下载错误',
              message: err || `Status:${err.response.status}，未知错误！`
            })
          })

        } else {
          ElNotification.error({
            title: '下载错误',
            message: res.response.data.message || `Status:${res.response.status}，未知错误！`
          })
        }
      })
    }
  },


  previewFile: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/file/download_url`,
    name: "预览文件",
    post: async function (objectName) {
      return await http.post(this.url, {object_name: objectName}).then(res => {
        if (res.code === 200) {
          let url = res.data.url
          window.open(`${process.env.VUE_APP_PREVIEW_HOST}/onlinePreview?url=` + encodeURIComponent(tool.crypto.BASE64.encrypt(url)));
        } else {
          ElNotification.error({
            title: '预览错误',
            message: res.message
          })
        }
      });
    }
  },

}
