import http from "@/utils/request"

export default {

  // 用户工单记录信息
  getUserDashboard: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/user/work_order/dashboard`,
    name: "查询用户工单统计信息",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    }
  },
  getUserWorkOrderList: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/user/work_order/list`,
    name: "查询用户工单记录列表",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    }
  },

  getWorkOrderFilterOptions: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/work_order/filter_options`,
    name: "查询工单列表",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    }
  },

  getWorkOrderList: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/work_order/list`,
    name: "查询工单列表",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    }
  },
  getWorkOrderInfo: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/work_order/info`,
    name: "查询工单记录",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    }
  },
  applyWorkOrder: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/work_order/apply`,
    name: "申领工单",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    }
  },

  addWorkOrderAtt: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/work_order/att/add`,
    name: "上传工单附件",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    }
  },
  removeWorkOrderAtt: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/work_order/att/del`,
    name: "移除工单附件",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    }
  },

  submitFeedback: {
    url: `${process.env.VUE_APP_BASE_URL}/portal/work_order/feedback/submit`,
    name: "提交反馈表",
    post: async function (data = {}) {
      return await http.post(this.url, data);
    }
  }
}
