import http from "@/utils/request"

export default {
    // 员工薪资
    getStaffPayrollHistory: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/staff/payroll/history`,
        name: "查询员工工资发放记录",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },
    getStaffPayrollItemCols: {
        url: `${process.env.VUE_APP_BASE_URL}/portal/staff/payroll/item_col_list`,
        name: "查询工资表项",
        post: async function (data = {}) {
            return await http.post(this.url, data);
        }
    },

}
