import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import api from '@/api'
import tool from '@/utils/tool'


// 导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import '@/assets/plugins/css/plugins.css'
import '@/assets/css/style.css'
import '@/assets/css/colors/blue-style.css'

import '@/assets/css/ssc.css'   //骨架屏css

// 表单组件
import FormMakingV3 from '@/libs/form-making-v3'
import '@/libs/form-making-v3/dist/index.css'

import questionRadio from "./components/form-making/survey/questionRadio";
import questionText from "./components/form-making/survey/questionText";
import questionTextarea from "./components/form-making/survey/questionTextarea";


import timeAgo from "./components/time-ago";

import 'bootstrap'

const app = createApp(App)
app.config.globalProperties.$API = api
app.config.globalProperties.$TOOL = tool

app.use(store)
app.use(router)
app.use(ElementPlus, {
  locale: zhCn,
})

app.use(FormMakingV3, {
  locale: zhCn,
  components: [
    {
      name: 'question-radio',
      component: questionRadio // 自定义组件 单选
    },
    {
      name: 'question-text',
      component: questionText // 自定义组件 单行输入
    },
    {
      name: 'question-textarea',
      component: questionTextarea // 自定义组件 多行输入
    },

  ]
})
app.component('timeAgo', timeAgo)

app.mount('#app')
