<template>
  <nav class="navbar navbar-default navbar-fixed navbar-light white bootsnav">
    <div class="container">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu"><i
          class="fa fa-bars"></i></button>
      <div class="navbar-header">
        <a class="navbar-brand" href="/"><img src="/img/logo.png" class="logo logo-scrolled"
                                              alt=""></a>
      </div>
      <div class="collapse navbar-collapse" id="navbar-menu">
        <ul class="nav navbar-nav navbar-left" data-in="fadeInDown" data-out="fadeOutUp">
          <!--          <li class="active">-->
          <!--            <input type="text" class="form-control" placeholder="招聘查询">-->
          <!--          </li>-->
          <li>
            <a href="/">首页</a>
          </li>
          <li class="dropdown megamenu-fw">
            <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">解决方案</a>
            <ul class="dropdown-menu megamenu-content" role="menu">
              <li>
                <div class="row">
                  <div class="col-menu col-md-6">
                    <h6 class="title">外包服务产品</h6>
                    <div class="content">
                      <ul class="menu-col">
                        <li><a href="/service/101">人事管理</a></li>
                        <li><a href="/service/102">人员派遣</a></li>
                        <li><a href="/service/103">岗位外包</a></li>
                        <li><a href="/service/104">员工福利</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-menu col-md-6">
                    <h6 class="title">企业解决方案</h6>
                    <div class="content">
                      <ul class="menu-col">
                        <li><a href="/service/201">招聘服务</a></li>
                        <li><a href="/service/202">咨询培训</a></li>
                        <li><a href="/service/204">残疾人就业</a></li>
                      </ul>
                    </div>
                  </div>
                </div><!-- end row -->
              </li>
            </ul>
          </li>
          <li>
            <a href="/jobs">招聘信息</a>
          </li>
          <li>
            <a href="/articles/1">项目资源</a>
          </li>
          <li>
            <a href="/contact">联系我们</a>
          </li>

        </ul>
        <ul class="nav navbar-nav navbar-right" data-in="fadeInDown" data-out="fadeOutUp">
          <template v-if="!is_login">
            <li>
              <router-link to='/login'><i class="fa fa-user" aria-hidden="true"></i>登录</router-link>
            </li>
            <li class="left-br">
              <router-link to="/register" class="signin">注册</router-link>
            </li>
          </template>
          <template v-else>
            <li>
              <a href="/user/profile">用户中心</a>
            </li>
          </template>
        </ul>


      </div>
    </div>
  </nav>

  <div class="clearfix"></div>

</template>

<script>
import tool from "@/utils/tool";

export default {
  name: "NavHeader",
  data() {
    return {}
  },
  computed: {
    is_login() {
      let token = null
      if (this.$TOOL.data.get("TOKEN")) {
        token = this.$TOOL.data.get("TOKEN");
      } else if (this.$TOOL.data.sessionStorageGet("TOKEN")) {
        token = this.$TOOL.data.sessionStorageGet("TOKEN");
      }
      return token != null
    },
  },
  async beforeCreate() {
    //获取字典
    let dict_res = await this.$API.auth.dict.post()
    if (dict_res.code === 200) {
      this.$TOOL.data.set("DICT", dict_res.data)
    } else {
      this.$message.warning(dict_res.message)
      return false
    }
  },
  mounted() {
    this.event()
    this.hoverDropdown()
    this.navbarSticky()
    this.navbarScrollspy()
    this.init()

    window.onresize = () => {
      this.hoverDropdown()

      $(".top-search").slideUp()
      setTimeout(() => {
        this.navbarSticky()
      }, 500)

      // Toggle Bars
      $(".navbar-toggle").each(() => {
        $(".fa", this).removeClass("fa-times")
        $(".fa", this).addClass("fa-bars")
        $(this).removeClass("fixed")
      })
      $(".navbar-collapse").removeClass("in")
      $(".navbar-collapse").removeClass("on")
      $(".navbar-collapse").removeClass("bounceIn")

    }
  },
  methods: {
    init() {

    },
    event() {

      // ------------------------------------------------------------------------------ //
      // Variable
      // ------------------------------------------------------------------------------ //
      var getNav = $("nav.navbar.bootsnav");

      // ------------------------------------------------------------------------------ //
      // Navbar Sticky
      // ------------------------------------------------------------------------------ //
      var navSticky = getNav.hasClass("navbar-sticky");
      if (navSticky) {
        // Wraped navigation
        getNav.wrap("<div class='wrap-sticky'></div>");
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Center
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("brand-center")) {
        var postsArr = new Array(),
            index = $("nav.brand-center"),
            $postsList = index.find('ul.navbar-nav');

        //Create array of all posts in lists
        index.find('ul.navbar-nav > li').each(function () {
          postsArr.push($(this).html());
        });

        //Split the array at this point. The original array is altered.
        var firstList = postsArr.splice(0, Math.round(postsArr.length / 2)),
            secondList = postsArr,
            ListHTML = '';

        var createHTML = function (list) {
          ListHTML = '';
          for (var i = 0; i < list.length; i++) {
            ListHTML += '<li>' + list[i] + '</li>'
          }
        }

        //Generate HTML for first list
        createHTML(firstList);
        $postsList.html(ListHTML);
        index.find("ul.nav").first().addClass("navbar-left");

        //Generate HTML for second list
        createHTML(secondList);
        //Create new list after original one
        $postsList.after('<ul class="nav navbar-nav"></ul>').next().html(ListHTML);
        index.find("ul.nav").last().addClass("navbar-right");

        //Wrap navigation menu
        index.find("ul.nav.navbar-left").wrap("<div class='col-half left'></div>");
        index.find("ul.nav.navbar-right").wrap("<div class='col-half right'></div>");

        //Selection Class
        index.find('ul.navbar-nav > li').each(function () {
          var dropDown = $("ul.dropdown-menu", this),
              megaMenu = $("ul.megamenu-content", this);
          dropDown.closest("li").addClass("dropdown");
          megaMenu.closest("li").addClass("megamenu-fw");
        });
      }


      // ------------------------------------------------------------------------------ //
      // Navbar Sidebar
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("navbar-sidebar")) {
        // Add Class to body
        $("body").addClass("wrap-nav-sidebar");
        getNav.wrapInner("<div class='scroller'></div>");
      } else {
        $(".bootsnav").addClass("on");
      }

      // ------------------------------------------------------------------------------ //
      // Menu Center
      // ------------------------------------------------------------------------------ //
      if (getNav.find("ul.nav").hasClass("navbar-center")) {
        getNav.addClass("menu-center");
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Full
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("navbar-full")) {
        // Add Class to body
        $("nav.navbar.bootsnav").find("ul.nav").wrap("<div class='wrap-full-menu'></div>");
        $(".wrap-full-menu").wrap("<div class='nav-full'></div>");
        $("ul.nav.navbar-nav").prepend("<li class='close-full-menu'><a href='#'><i class='fa fa-times'></i></a></li>");
      } else if (getNav.hasClass("navbar-mobile")) {
        getNav.removeClass("no-full");
      } else {
        getNav.addClass("no-full");
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Mobile
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("navbar-mobile")) {
        // Add Class to body
        $('.navbar-collapse').on('shown.bs.collapse', function () {
          $("body").addClass("side-right");
        });
        $('.navbar-collapse').on('hide.bs.collapse', function () {
          $("body").removeClass("side-right");
        });

        $(window).on("resize", function () {
          $("body").removeClass("side-right");
        });
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Fixed
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("no-background")) {
        $(window).on("scroll", function () {
          var scrollTop = $(window).scrollTop();
          if (scrollTop > 34) {
            $(".navbar-fixed").removeClass("no-background");
          } else {
            $(".navbar-fixed").addClass("no-background");
          }
        });
      }

      // ------------------------------------------------------------------------------ //
      // Navbar Fixed
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("navbar-transparent")) {
        $(window).on("scroll", function () {
          var scrollTop = $(window).scrollTop();
          if (scrollTop > 34) {
            $(".navbar-fixed").removeClass("navbar-transparent");
          } else {
            $(".navbar-fixed").addClass("navbar-transparent");
          }
        });
      }

      // ------------------------------------------------------------------------------ //
      // Button Cart
      // ------------------------------------------------------------------------------ //
      $(".btn-cart").on("click", function (e) {
        e.stopPropagation();
      });

      // ------------------------------------------------------------------------------ //
      // Toggle Search
      // ------------------------------------------------------------------------------ //
      $("nav.navbar.bootsnav .attr-nav").each(function () {
        $("li.search > a", this).on("click", function (e) {
          e.preventDefault();
          $(".top-search").slideToggle();
        });
      });
      $(".input-group-addon.close-search").on("click", function () {
        $(".top-search").slideUp();
      });

      // ------------------------------------------------------------------------------ //
      // Toggle Side Menu
      // ------------------------------------------------------------------------------ //
      $("nav.navbar.bootsnav .attr-nav").each(function () {
        $("li.side-menu > a", this).on("click", function (e) {
          e.preventDefault();
          $("nav.navbar.bootsnav > .side").toggleClass("on");
          $("body").toggleClass("on-side");
        });
      });
      $(".side .close-side").on("click", function (e) {
        e.preventDefault();
        $("nav.navbar.bootsnav > .side").removeClass("on");
        $("body").removeClass("on-side");
      });


      // ------------------------------------------------------------------------------ //
      // Wrapper
      // ------------------------------------------------------------------------------ //
      // $("body").wrapInner("<div class='wrapper'></div>");
    },
    // ------------------------------------------------------------------------------ //
    // Change dropdown to hover on dekstop
    // ------------------------------------------------------------------------------ //
    hoverDropdown() {
      var getNav = $("nav.navbar.bootsnav"),
          getWindow = $(window).width(),
          getHeight = $(window).height(),
          getIn = getNav.find("ul.nav").data("in"),
          getOut = getNav.find("ul.nav").data("out");

      if (getWindow < 981) {

        // Height of scroll navigation sidebar
        $(".scroller").css("height", "auto");

        // Disable mouseenter event
        $("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseenter");
        $("nav.navbar.bootsnav ul.nav").find("li.dropdown").off("mouseleave");
        $("nav.navbar.bootsnav ul.nav").find(".title").off("mouseenter");
        $("nav.navbar.bootsnav ul.nav").off("mouseleave");
        $(".navbar-collapse").removeClass("animated");

        // Enable click event
        $("nav.navbar.bootsnav ul.nav").each(function () {
          $(".dropdown-menu", this).addClass("animated");
          $(".dropdown-menu", this).removeClass(getOut);

          // Dropdown Fade Toggle
          $("a.dropdown-toggle", this).off('click');
          $("a.dropdown-toggle", this).on('click', function (e) {
            e.stopPropagation();
            $(this).closest("li.dropdown").find(".dropdown-menu").first().stop().fadeToggle().toggleClass(getIn);
            $(this).closest("li.dropdown").first().toggleClass("on");
            return false;
          });

          // Hidden dropdown action
          $('li.dropdown', this).each(function () {
            $(this).find(".dropdown-menu").stop().fadeOut();
            $(this).on('hidden.bs.dropdown', function () {
              $(this).find(".dropdown-menu").stop().fadeOut();
            });
            return false;
          });

          // Megamenu style
          $(".megamenu-fw", this).each(function () {
            $(".col-menu", this).each(function () {
              $(".content", this).addClass("animated");
              $(".content", this).stop().fadeOut();
              $(".title", this).off("click");
              $(".title", this).on("click", function () {
                $(this).closest(".col-menu").find(".content").stop().fadeToggle().addClass(getIn);
                $(this).closest(".col-menu").toggleClass("on");
                return false;
              });

              $(".content", this).on("click", function (e) {
                e.stopPropagation();
              });
            });
          });
        });

        // Hidden dropdown
        var cleanOpen = function () {
          $('li.dropdown', this).removeClass("on");
          $(".dropdown-menu", this).stop().fadeOut();
          $(".dropdown-menu", this).removeClass(getIn);
          $(".col-menu", this).removeClass("on");
          $(".col-menu .content", this).stop().fadeOut();
          $(".col-menu .content", this).removeClass(getIn);
        }

        // Hidden om mouse leave
        $("nav.navbar.bootsnav").on("mouseleave", function () {
          cleanOpen();
        });

        // Enable click atribute navigation
        $("nav.navbar.bootsnav .attr-nav").each(function () {
          $(".dropdown-menu", this).removeClass("animated");
          $("li.dropdown", this).off("mouseenter");
          $("li.dropdown", this).off("mouseleave");
          $("a.dropdown-toggle", this).off('click');
          $("a.dropdown-toggle", this).on('click', function (e) {
            e.stopPropagation();
            $(this).closest("li.dropdown").find(".dropdown-menu").first().stop().fadeToggle();
            $(".navbar-toggle").each(function () {
              $(".fa", this).removeClass("fa-times");
              $(".fa", this).addClass("fa-bars");
              $(".navbar-collapse").removeClass("in");
              $(".navbar-collapse").removeClass("on");
            });
          });

          $(this).on("mouseleave", function () {
            $(".dropdown-menu", this).stop().fadeOut();
            $("li.dropdown", this).removeClass("on");
            return false;
          });
        });

        // Toggle Bars
        $(".navbar-toggle").each(function () {
          $(this).off("click");
          $(this).on("click", function () {
            $(".fa", this).toggleClass("fa-bars");
            $(".fa", this).toggleClass("fa-times");
            cleanOpen();
          });
        });

      } else if (getWindow > 981) {
        // Height of scroll navigation sidebar
        $(".scroller").css("height", getHeight + "px");

        // Navbar Sidebar
        if (getNav.hasClass("navbar-sidebar")) {
          // Hover effect Sidebar Menu
          $("nav.navbar.bootsnav ul.nav").each(function () {
            $("a.dropdown-toggle", this).off('click');
            $("a.dropdown-toggle", this).on('click', function (e) {
              e.stopPropagation();
            });

            $(".dropdown-menu", this).addClass("animated");
            $("li.dropdown", this).on("mouseenter", function () {
              $(".dropdown-menu", this).eq(0).removeClass(getOut);
              $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
              $(this).addClass("on");
              return false;
            });

            $(".col-menu").each(function () {
              $(".content", this).addClass("animated");
              $(".title", this).on("mouseenter", function () {
                $(this).closest(".col-menu").find(".content").stop().fadeIn().addClass(getIn);
                $(this).closest(".col-menu").addClass("on");
                return false;
              });
            });

            $(this).on("mouseleave", function () {
              $(".dropdown-menu", this).stop().removeClass(getIn);
              $(".dropdown-menu", this).stop().addClass(getOut).fadeOut();
              $(".col-menu", this).find(".content").stop().fadeOut().removeClass(getIn);
              $(".col-menu", this).removeClass("on");
              $("li.dropdown", this).removeClass("on");
              return false;
            });
          });
        } else {
          // Hover effect Default Menu
          $("nav.navbar.bootsnav ul.nav").each(function () {
            $("a.dropdown-toggle", this).off('click');
            $("a.dropdown-toggle", this).on('click', function (e) {
              e.stopPropagation();
            });

            $(".megamenu-fw", this).each(function () {
              $(".title", this).off("click");
              $("a.dropdown-toggle", this).off("click");
              $(".content").removeClass("animated");
            });

            $(".dropdown-menu", this).addClass("animated");
            $("li.dropdown", this).on("mouseenter", function () {
              $(".dropdown-menu", this).eq(0).removeClass(getOut);
              $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
              $(this).addClass("on");
              return false;
            });

            $("li.dropdown", this).on("mouseleave", function () {
              $(".dropdown-menu", this).eq(0).removeClass(getIn);
              $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
              $(this).removeClass("on");
            });

            $(this).on("mouseleave", function () {
              $(".dropdown-menu", this).removeClass(getIn);
              $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
              $("li.dropdown", this).removeClass("on");
              return false;
            });
          });
        }

        // ------------------------------------------------------------------------------ //
        // Hover effect Atribute Navigation
        // ------------------------------------------------------------------------------ //
        $("nav.navbar.bootsnav .attr-nav").each(function () {
          $("a.dropdown-toggle", this).off('click');
          $("a.dropdown-toggle", this).on('click', function (e) {
            e.stopPropagation();
          });

          $(".dropdown-menu", this).addClass("animated");
          $("li.dropdown", this).on("mouseenter", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getOut);
            $(".dropdown-menu", this).eq(0).stop().fadeIn().addClass(getIn);
            $(this).addClass("on");
            return false;
          });

          $("li.dropdown", this).on("mouseleave", function () {
            $(".dropdown-menu", this).eq(0).removeClass(getIn);
            $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
            $(this).removeClass("on");
          });

          $(this).on("mouseleave", function () {
            $(".dropdown-menu", this).removeClass(getIn);
            $(".dropdown-menu", this).eq(0).stop().fadeOut().addClass(getOut);
            $("li.dropdown", this).removeClass("on");
            return false;
          });
        });
      }

      // ------------------------------------------------------------------------------ //
      // Menu Fullscreen
      // ------------------------------------------------------------------------------ //
      if (getNav.hasClass("navbar-full")) {
        var windowHeight = $(window).height(),
            windowWidth = $(window).width();

        $(".nav-full").css("height", windowHeight + "px");
        $(".wrap-full-menu").css("height", windowHeight + "px");
        $(".wrap-full-menu").css("width", windowWidth + "px");

        $(".navbar-collapse").addClass("animated");
        $(".navbar-toggle").each(function () {
          var getId = $(this).data("target");
          $(this).off("click");
          $(this).on("click", function (e) {
            e.preventDefault();
            $(getId).removeClass(getOut);
            $(getId).addClass("in");
            $(getId).addClass(getIn);
            return false;
          });

          $("li.close-full-menu").on("click", function (e) {
            e.preventDefault();
            $(getId).addClass(getOut);
            setTimeout(function () {
              $(getId).removeClass("in");
              $(getId).removeClass(getIn);
            }, 500);
            return false;
          });
        });
      }
    },

    // ------------------------------------------------------------------------------ //
    // Navbar Sticky
    // ------------------------------------------------------------------------------ //
    navbarSticky() {
      var getNav = $("nav.navbar.bootsnav"),
          navSticky = getNav.hasClass("navbar-sticky");

      if (navSticky) {

        // Set Height Navigation
        var getHeight = getNav.height();
        $(".wrap-sticky").height(getHeight);

        // Windown on scroll
        var getOffset = $(".wrap-sticky").offset().top;
        $(window).on("scroll", function () {
          var scrollTop = $(window).scrollTop();
          if (scrollTop > getOffset) {
            getNav.addClass("sticked");
          } else {
            getNav.removeClass("sticked");
          }
        });
      }
    },

    // ------------------------------------------------------------------------------ //
    // Navbar Scrollspy
    // ------------------------------------------------------------------------------ //
    navbarScrollspy() {
      var navScrollSpy = $(".navbar-scrollspy"),
          $body = $('body'),
          getNav = $('nav.navbar.bootsnav'),
          offset = getNav.outerHeight();

      if (navScrollSpy.length) {
        $body.scrollspy({target: '.navbar', offset: offset});

        // Animation Scrollspy
        $('.scroll').on('click', function (event) {
          event.preventDefault();

          // Active link
          $('.scroll').removeClass("active");
          $(this).addClass("active");

          // Remove navbar collapse
          $(".navbar-collapse").removeClass("in");

          // Toggle Bars
          $(".navbar-toggle").each(function () {
            $(".fa", this).removeClass("fa-times");
            $(".fa", this).addClass("fa-bars");
          });

          // Scroll
          var scrollTop = $(window).scrollTop(),
              $anchor = $(this).find('a'),
              $section = $($anchor.attr('href')).offset().top,
              $window = $(window).width(),
              $minusDesktop = getNav.data("minus-value-desktop"),
              $minusMobile = getNav.data("minus-value-mobile"),
              $speed = getNav.data("speed");

          if ($window > 992) {
            var $position = $section - $minusDesktop;
          } else {
            var $position = $section - $minusMobile;
          }

          $('html, body').stop().animate({
            scrollTop: $position
          }, $speed);
        });

        // Activate Navigation
        var fixSpy = function () {
          var data = $body.data('bs.scrollspy');
          if (data) {
            offset = getNav.outerHeight();
            data.options.offset = offset;
            $body.data('bs.scrollspy', data);
            $body.scrollspy('refresh');
          }
        }

        // Activate Navigation on resize
        var resizeTimer;
        $(window).on('resize', function () {
          clearTimeout(resizeTimer);
          var resizeTimer = setTimeout(fixSpy, 200);
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
