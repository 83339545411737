<template>
  <div class="custom-component">
    <div v-if="printRead">
      <div class="text-block">{{ dataModel }}</div>
    </div>
    <div v-else>
      <el-input v-model="dataModel" class="text-item"></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'question-text',
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    printRead: {
      type: Boolean,
    },
  },
  data() {
    return {
      dataModel: this.modelValue
    }
  },
  watch: {
    modelValue(val) {
      this.dataModel = val
    },
    dataModel(val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>

<style lang="scss">
.custom-component {
  padding: 10px;

}

.text-block {
  background-color: var(--el-background-color-base);
  padding: 10px;
}

.text-item {
  width: 100%;

  .el-input__wrapper {
    border-radius: 0;
    box-shadow: 0 0 0 1px #e3e3e3 inset;
  }

  .is-focus {
    box-shadow: 0 0 0 1px #0c5ead inset !important;
  }

  input {
    font-size: 16px;
    padding: 20px 16px;
  }
}
</style>
