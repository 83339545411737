export default {
    state: {
        //移动端布局
        ismobile: false,

        //保存上一页
        from_path: null,
    },
    mutations: {
        SET_ismobile(state, key) {
            state.ismobile = key
        },
        SET_from_path(state, key) {
            state.from_path = key
        },
    }
}
