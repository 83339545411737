<template>
  <div class="custom-component">
    <div v-if="printRead">
      <template v-for="(option,index) in optionsModel" :key="index">
        <div v-if="option.value===dataModel" class="text-block">{{ option.value }}</div>
      </template>
    </div>
    <div v-else>
      <el-radio-group v-model="dataModel">
        <el-radio v-for="(option,index) in optionsModel" :key="index" :label="option.value" size="large" class="radio-item"/>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'question-radio',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    printRead: {
      type: Boolean,
    },
  },
  data() {
    return {
      dataModel: this.modelValue,
      optionsModel: this.options
    }
  },
  watch: {
    modelValue(val) {
      this.dataModel = val
    },
    dataModel(val) {
      this.$emit('update:modelValue', val)
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">
.custom-component {
  padding: 10px;

}

.text-block {
  background-color: var(--el-background-color-base);
  padding: 10px;
}

.el-radio-group {
  width: 100%;
  border: 1px solid #e3e3e3;
  border-bottom: none;
  border-right: none;
}

.radio-item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100% !important;
  padding: 8px 12px;
  margin-right: 0 !important;
  margin-bottom: 0 !important;

  border-bottom: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;

  span.el-radio__input {
    display: block;
  }

  span.el-radio__label {
    display: block;
    word-wrap: break-word;
    white-space: pre-wrap;
    margin-left: 6px;

    font-size: 16px !important;
  }
}
</style>
