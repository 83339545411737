/*
 * @Descripttion: 工具集
 * @version: 1.1
 * @LastEditors: sakuya
 * @LastEditTime: 2021年7月20日10:58:41
 */

import CryptoJS from 'crypto-js';

const tool = {}

/* localStorage */
tool.data = {
  set(table, settings) {
    var _set = JSON.stringify(settings)
    return localStorage.setItem(table, _set);
  },
  sessionStorageSet(table, settings) {
    var _set = JSON.stringify(settings)
    return sessionStorage.setItem(table, _set);
  },
  get(table) {
    var data = localStorage.getItem(table);
    try {
      data = JSON.parse(data)
    } catch (err) {
      return null
    }
    return data;
  },
  sessionStorageGet(table) {
    var data = sessionStorage.getItem(table);
    try {
      data = JSON.parse(data)
    } catch (err) {
      return null
    }
    return data;
  },
  remove(table) {
    return localStorage.removeItem(table);
  },
  sessionStorageRemove(table) {
    return sessionStorage.removeItem(table);
  },
  clear() {
    return localStorage.clear();
  }
}

/*sessionStorage*/
tool.session = {
  set(table, settings) {
    var _set = JSON.stringify(settings)
    return sessionStorage.setItem(table, _set);
  },
  get(table) {
    var data = sessionStorage.getItem(table);
    try {
      data = JSON.parse(data)
    } catch (err) {
      return null
    }
    return data;
  },
  remove(table) {
    return sessionStorage.removeItem(table);
  },
  clear() {
    return sessionStorage.clear();
  }
}

/* Fullscreen */
tool.screen = function (element) {
  var isFull = !!(document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
  if (isFull) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  } else {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  }
}

/* 复制对象 */
tool.objCopy = function (obj) {
  return JSON.parse(JSON.stringify(obj));
}

/* 日期格式化 */
tool.dateFormat = function (date, fmt = 'yyyy-MM-dd hh:mm:ss') {
  date = new Date(date)
  var o = {
    "M+": date.getMonth() + 1,                 //月份
    "d+": date.getDate(),                    //日
    "h+": date.getHours(),                   //小时
    "m+": date.getMinutes(),                 //分
    "s+": date.getSeconds(),                 //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
}

/* 千分符 */
tool.groupSeparator = function (num) {
  num = num + '';
  if (!num.includes('.')) {
    num += '.'
  }
  return num.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
    return $1 + ',';
  }).replace(/\.$/, '');
}

/* 常用加解密 */
tool.crypto = {
  //MD5加密
  MD5(data) {
    return CryptoJS.MD5(data).toString()
  },
  //BASE64加解密
  BASE64: {
    encrypt(data) {
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data))
    },
    decrypt(cipher) {
      return CryptoJS.enc.Base64.parse(cipher).toString(CryptoJS.enc.Utf8)
    }
  },
  //AES加解密
  AES: {
    encrypt(data, secretKey) {
      const result = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
      return result.toString()
    },
    decrypt(cipher, secretKey) {
      const result = CryptoJS.AES.decrypt(cipher, CryptoJS.enc.Utf8.parse(secretKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
      return CryptoJS.enc.Utf8.stringify(result);
    }
  }
}

//获取字典数据
tool.getDict = function (dictItem) {
  let dict = tool.data.get('DICT')
  return dict[dictItem]

}
tool.getLabel = function (option, id) {
  let label = null
  option.forEach(item => {
    if (item.id == id) {
      label = item.name
    }
  })
  return label
}

/**
 * js截取字符串，中英文都能用
 * @param str：需要截取的字符串
 * @param len: 需要截取的长度
 */
tool.cutStr = function (str, len) {
  let str_length = 0;
  let str_len = 0;
  let str_cut = String();
  str_len = str.length;
  for (var i = 0; i < str_len; i++) {
    let a = str.charAt(i);
    str_length++;
    if (escape(a).length > 4) {
      //中文字符的长度经编码之后大于4
      str_length++;
    }
    str_cut = str_cut.concat(a);
    if (str_length >= len) {
      str_cut = str_cut.concat("...");
      return str_cut;
    }
  }
  //如果给定字符串小于指定长度，则返回源字符串；
  if (str_length < len) {
    return str;
  }
}

// 判断字符串非空
tool.isEmptyStr = function (str) {
  return !(str != null && str.trim().length > 0);
}

tool.toTop = function () {
  document.documentElement.scrollTop = 0;
}

tool.get_work_order_status_color = function (status_id) {
  if (status_id === 119001) return 'info'
  if (status_id === 119002) return 'warning'
  if (status_id === 119003) return 'success'
  if ([119005, 119007].includes(status_id)) return 'warning'
  if (status_id === 119006) return 'primary'
  if ([119004, 119008].includes(status_id)) return 'info'

  return "info"
}

//判断时间是否在未来  在有效期内
tool.is_time_in_valid = function (time) {
  const compareTime = new Date(time)
  const now = new Date();
  const diff = now - compareTime;

  return diff < 0
}


export default tool
